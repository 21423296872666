.Toolbar {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: 4px;
    height: 56px;
    box-sizing: border-box;
    background-color: #fff;
    color: #292828;
    box-shadow: 0 0 12px 1px rgb(0 0 0 / 25%);
    z-index: 999;
    width: 100%;
}

.Wrapper {
    height: 100%;
    max-width: var(--wrapper-max-width);
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.LogoContainer {
    max-width: 120px;
}

.AppName {
    font-size: 10pt;
    line-height: 1.2em;
    padding-bottom: .4em;
}

.BackButton {
    margin-left: auto;
    padding-right: 8px;
}