.App {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.Content {
    flex: 1;
    margin: 64px 16px 50px;
}