.Footer {
    margin: 0;
    padding: 24px;
    background-color: var(--background-color-footer);
    color: #fff;
    line-height: 24px;
}

.Wrapper {
    max-width: var(--wrapper-max-width);
    margin: 0 auto;
}

.Footer h2 {
    font-size: 28px;
    color: #fff;
    padding-top: 16px;
}

.Divider {
    margin: 3em 0;
    text-align: center;
    width: 60px;
    display: inline-block;
    border-top: 4px solid var(--highlight-color-light);
}

.ContactRow {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ContactIcon {
    padding: 0 16px;
    width: 50px;
}