@charset "UTF-8";
@import '//fonts.googleapis.com/css?family=Lato:300,400,400italic,700,900%7CMontserrat:400,700,900';
:root {
    --background-color-toolbar: #ffffff;
    --background-color: #f5f5f5;
    --background-color-input: #ffffff;
    --background-color-footer: #292828;
    --highlight-color-light: #37b2e7;
    --highlight-color-dark: #2b97c5;
    --font-standard: 400 16px "Lato", Helvetica, Arial, sans-serif;
    --font-family-special: "Montserrat", sans-serif;
    --font-color-light: #5e5e5e;
    --font-color-dark: #292828;
    --text-shadow: 0.05em 0.05em 0.2em rgb(0 0 0 / 30%);
    --button-width: 200px;
    --wrapper-max-width: 600px;
    box-sizing: border-box;
}

body {
    font: var(--font-standard);
    font-size: 16px;
    line-height: 1.5;
    color: var(--font-color-light);
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
}

a {
    color: var(--highlight-color-light);
    text-decoration: none;
    -webkit-transition: 270ms ease-in;
    transition: 270ms ease-in;
}

a:hover,
a:focus {
    color: var(--highlight-color-dark);
    text-decoration: none;
    outline: none;
}

a[href*='callto'],
a[href*='mailto'] {
    white-space: nowrap;
}

.p a:hover,
.list a:hover {
    text-decoration: underline;
}

p {
    margin: 0;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}


/*
*
* Typography
* ==================================================
*/

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: var(--font-family-special);
    font-weight: 700;
    color: var(--font-color-dark);
}

h1 {
    font-size: 28px;
    text-align: center;
    text-shadow: var(--text-shadow);
}