.Button {
    width: 100%;
    background-color: var(--highlight-color-light);
    border: none;
    color: black;
    outline: none;
    cursor: pointer;
    font: 400 16px var(--font-family-special);
    padding: 24px;
    color: #fff
}

.small {
    padding: 8px 24px;
}

.margin {
    margin: 4px;
}

.maxWidth {
    flex: 1;
}

.Button:disabled {
    color: var(--highlight-color-dark);
    cursor: not-allowed;
}