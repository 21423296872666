@import url(//fonts.googleapis.com/css?family=Lato:300,400,400italic,700,900%7CMontserrat:400,700,900);
@charset "UTF-8";
:root {
    --background-color-toolbar: #ffffff;
    --background-color: #f5f5f5;
    --background-color-input: #ffffff;
    --background-color-footer: #292828;
    --highlight-color-light: #37b2e7;
    --highlight-color-dark: #2b97c5;
    --font-standard: 400 16px "Lato", Helvetica, Arial, sans-serif;
    --font-family-special: "Montserrat", sans-serif;
    --font-color-light: #5e5e5e;
    --font-color-dark: #292828;
    --text-shadow: 0.05em 0.05em 0.2em rgb(0 0 0 / 30%);
    --button-width: 200px;
    --wrapper-max-width: 600px;
    box-sizing: border-box;
}

body {
    font: 400 16px "Lato", Helvetica, Arial, sans-serif;
    font: var(--font-standard);
    font-size: 16px;
    line-height: 1.5;
    color: #5e5e5e;
    color: var(--font-color-light);
    background-color: #f5f5f5;
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
}

a {
    color: #37b2e7;
    color: var(--highlight-color-light);
    text-decoration: none;
    transition: 270ms ease-in;
}

a:hover,
a:focus {
    color: #2b97c5;
    color: var(--highlight-color-dark);
    text-decoration: none;
    outline: none;
}

a[href*='callto'],
a[href*='mailto'] {
    white-space: nowrap;
}

.p a:hover,
.list a:hover {
    text-decoration: underline;
}

p {
    margin: 0;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}


/*
*
* Typography
* ==================================================
*/

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;
    font-family: var(--font-family-special);
    font-weight: 700;
    color: #292828;
    color: var(--font-color-dark);
}

h1 {
    font-size: 28px;
    text-align: center;
    text-shadow: 0.05em 0.05em 0.2em rgb(0 0 0 / 30%);
    text-shadow: var(--text-shadow);
}
.App_App__16ZpL {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.App_Content__ZaMNr {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 64px 16px 50px;
}
.Spinner_Loader__1twK- {
    color: var(--highlight-color-light);
    font-size: 20px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: Spinner_load4__dJ24G 1.3s infinite linear;
    animation: Spinner_load4__dJ24G 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

@-webkit-keyframes Spinner_load4__dJ24G {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@keyframes Spinner_load4__dJ24G {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}
.Toolbar_Toolbar__2QS0u {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: 4px;
    height: 56px;
    box-sizing: border-box;
    background-color: #fff;
    color: #292828;
    box-shadow: 0 0 12px 1px rgb(0 0 0 / 25%);
    z-index: 999;
    width: 100%;
}

.Toolbar_Wrapper__1DEQ- {
    height: 100%;
    max-width: var(--wrapper-max-width);
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.Toolbar_LogoContainer__3aNaR {
    max-width: 120px;
}

.Toolbar_AppName__yAkCh {
    font-size: 10pt;
    line-height: 1.2em;
    padding-bottom: .4em;
}

.Toolbar_BackButton__1cKWv {
    margin-left: auto;
    padding-right: 8px;
}
.Logo_Logo__1N0xH {
    width: 100%;
}

.Logo_Logo__1N0xH img {
    width: 100%;
    height: auto;
    vertical-align: middle;
}
.LegalNotice_LegalNotice__TJr59 {
    width: 100%;
    max-width: var(--wrapper-max-width);
    margin: 24px auto 0;
}

.LegalNotice_LegalLinks__3g6Zh {
    width: 100%;
    margin-top: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.LegalNotice_ExternalLink__3jsLd {
    display: block;
    width: var(--button-width);
    margin: 4px;
}
.Button_Button__3gFiX {
    width: 100%;
    background-color: var(--highlight-color-light);
    border: none;
    color: black;
    outline: none;
    cursor: pointer;
    font: 400 16px var(--font-family-special);
    padding: 24px;
    color: #fff
}

.Button_small__1o_ew {
    padding: 8px 24px;
}

.Button_margin__Y_2SJ {
    margin: 4px;
}

.Button_maxWidth__uWvCF {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.Button_Button__3gFiX:disabled {
    color: var(--highlight-color-dark);
    cursor: not-allowed;
}
.Footer_Footer__238hM {
    margin: 0;
    padding: 24px;
    background-color: var(--background-color-footer);
    color: #fff;
    line-height: 24px;
}

.Footer_Wrapper__1bsCi {
    max-width: var(--wrapper-max-width);
    margin: 0 auto;
}

.Footer_Footer__238hM h2 {
    font-size: 28px;
    color: #fff;
    padding-top: 16px;
}

.Footer_Divider__2gMbo {
    margin: 3em 0;
    text-align: center;
    width: 60px;
    display: inline-block;
    border-top: 4px solid var(--highlight-color-light);
}

.Footer_ContactRow__V2VHU {
    margin-bottom: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.Footer_ContactIcon__3xqeD {
    padding: 0 16px;
    width: 50px;
}
