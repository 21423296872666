.LegalNotice {
    width: 100%;
    max-width: var(--wrapper-max-width);
    margin: 24px auto 0;
}

.LegalLinks {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.ExternalLink {
    display: block;
    width: var(--button-width);
    margin: 4px;
}